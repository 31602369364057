<template>
  <div>
    <div v-if="!spinnerLoading" class="mx-3">
      <div class="mt-2">
        <div class="justify-content-center m-1">
          <div class="d-flex justify-content-center btn-group">
            <input
              v-model="searchText"
              type="text"
              class="form-control fs-5 w-100"
              placeholder="Товар"
            />
            <button
              type="button"
              class="btn btn-secondary"
              @click="clearSearchText()"
            >
              Очистить
            </button>
          </div>
        </div>

        <div class="row m-2">Всего/Готово: {{goods.length}} / {{ goodsCollected }} </div>

        <div class="row fw-bold">
          <div class="col">Номенклатура</div>
          <div class="col">План</div>
          <div class="col">Факт</div>
          <div class="col">Готово</div>
        </div>

        <div>
          <div
            v-for="(goodsRow, productIndex) in docGoodsFilter"
            :key="goodsRow._id"
            :id="`inventory-row-${productIndex}`"
            class="row cols-4 border"
            @click="selectRow(goodsRow._id, productIndex)"
            :class="{
              'selected-row': goodsRow._id == currentRowId,
            }"
          >
            <div class="col-5 px-0">
              <div>
                {{ goodsRow.productName }}
              </div>
            </div>
            <div
              class="col ps-1 fw-bold"
              style="min-width: 50px; max-width: 70px;"
            >
              <input
                v-model="goodsRow.qtyOrder"
                type="number"
                class="form-control px-0"
                placeholder=""
                disabled
              />
            </div>
            <div class="col px-0" style="min-width: 50px; max-width: 70px;">
              <input
                v-model="goodsRow.qtyFact"
                type="number"
                class="form-control px-0"
                placeholder=""
                @input="goodsRow.edit = true"
                :disabled="goodsRow.closed"
              />
            </div>
            <div class="col" style="max-width: 180px;">
              <div class="row">
                <div class="col">
                  <div
                    class="
                form-check form-switch
                mb-3
                "
                  >
                    <input
                      style="width: 3rem; height: 1.5rem"
                      class="form-check-input"
                      type="checkbox"
                      v-model="goodsRow.collected"
                      @input="goodsRow.edit = true"
                      @change="fillCollected(productIndex)"
                      :disabled="goodsRow.closed"
                    />
                  </div>
                </div>
                <div class="col">
                  <button
                    v-if="goodsRow.edit"
                    class="btn btn-light"
                    :disabled="goodsRow.closed"
                    @click="saveGoods(goodsRow)"
                  >
                    Сохр
                    <div
                      v-if="goodsRow.spinnerSave"
                      class="spinner-border text-primary d-flex justify-content-center"
                      role="status"
                    ></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
//import { onMounted, onUnmounted } from "vue";
//import router from "../router";

export default {
  components: {},
  props: {
    deliveryDate: { type: String, default: null },
    orderTypeIncome: { type: Boolean, default: true },
    store: { type: String, default: "ALL" },
    orderType: { type: String, default: "" },
  },

  setup(props) {
    const spinnerSave = ref(false);
    const spinnerLoading = ref(false);
    const dateFormat = (date) => {
      let dateFormated = new Date(date);
      return dateFormated.toLocaleString();
    };

    const filterCursor = ref(10);
    const filterStep = ref(100);
    const filterMax = ref(10000);
    const errorSaving = ref("");
    const goods = ref([]);

    const searchText = ref("");

    const getGoods = async () => {
      console.log("props.deliveryDate", props.deliveryDate);
      //let orderType = props.orderTypeIncome ? "income" : "outcome";
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          "/getSkladOrderGoods/byDate/" +
          props.deliveryDate +
          "/" +
          props.orderType +
          "/" +
          props.store,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );

      console.log(
        process.env.VUE_APP_API_URL +
          "/getSkladOrderGoods/byDate/" +
          props.deliveryDate +
          "/" +
          props.orderType +
          "/" +
          props.store,
        res.status
      );

      if (res.status == 200) {
        goods.value = await res.json();
      }
    };

    const saveGoods = async (good) => {
      errorSaving.value = "";
      good.spinnerSave = true;
      let res = await fetch(
        process.env.VUE_APP_API_URL + "/saveSkladOrderGoods/",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(good),
        }
      );
      console.log(res.status);
      if (res.status == 200) {
        good.spinnerSave = false;
        good.edit = false;
      } else {
        errorSaving.value = await res.json();
        good.spinnerSave = false;
      }
    };

    const fillCollected = (index) => {
      if (!goods.value[index].qtyFact && goods.value[index].collected) {
        goods.value[index].qtyFact = goods.value[index].qtyOrder;
      }
    };

    const currentRow = ref(null);
    const currentRowId = ref(null);
    const selectRow = (rowId, productIndex) => {
      let rowIndex = goods.value.findIndex((item) => item._id == rowId);
      currentRow.value = rowIndex;
      currentRowId.value = rowId;
      filterCursor.value = productIndex;
    };

    const clearSearchText = () => {
      searchText.value = "";
    };

    const docGoodsFilter = computed(() => {
      let result = goods.value;

      if (searchText.value.length >= 2) {
        result = result.filter(
          (item) =>
            item.productName
              .toLowerCase()
              .indexOf(searchText.value.toLowerCase()) >= 0
        );
      }

      if (result && result.length > filterMax.value) {
        let filterStart =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
        let filterEnd = filterCursor.value + filterStep.value;

        result = result.slice(filterStart, filterEnd);
      }
      return result;
    });

    const goodsCollected = computed(() => {
      let result = 0;
      console.log('goods.value', goods.value);
      
      goods.value.forEach(item => {
        if (item.collected) result += 1
      })
      // if(Array.isArray(goods.value)) {
      //   let collectedGoods = goods.value.filted((item) => item.collected);
      //   result = collectedGoods.length;
      // }
      return result;
    });

    onMounted(async () => {
      spinnerLoading.value = true;

      if (props.deliveryDate) {
        await getGoods();
      }
      spinnerLoading.value = false;
    });

    return {
      dateFormat,
      saveGoods,
      spinnerSave,
      spinnerLoading,
      fillCollected,
      selectRow,
      currentRow,
      docGoodsFilter,
      filterCursor,
      currentRowId,
      errorSaving,
      searchText,
      clearSearchText,
      goodsCollected,
      goods,
    };
  },
};
</script>

<style>
:root {
  --ms-px: 0.4rem;
}

.lh {
  line-height: 1;
}

.selected-row {
  background-color: #00ff9d;
}
.adding-barcode-product {
  background-color: #e1f100a2;
}
.fill-product-by-barcode {
  background-color: #1000f149;
}
</style>
