<template>
  <div>
    <div id="scanner"></div>
    <div v-if="!spinnerLoading" class="mx-3">
      <div v-if="!spinnerSave" class="mt-2">
        <div class="row">
          <span class="text-danger">{{ errorSaving }}</span>
        </div>

        <div v-if="!isList" class="mt-1">
          <input
            v-model="searchText"
            type="text"
            class="form-control px-0"
            placeholder="Поиск"
          />
        </div>

        <div class="d-flex justify-content-center btn-group mt-1">
          <button
            v-if="isOrderDraftWriteProduct() || isOrderDraftCheckProduct()"
            class="btn btn-primary"
            @click="saveDoc()"
          >
            СОХРАНИТЬ
          </button>
          <button
            v-if="isOrderDraftWriteProduct()"
            class="btn btn-light mx-1"
            @click="addRow()"
          >
            Строка +
          </button>
        </div>
      </div>
      <div v-if="spinnerSave" class="d-flex justify-content-center mt-3">
        <div
          class="spinner-border text-primary d-flex justify-content-center"
          role="status"
        ></div>
      </div>

      <div class="mt-2">
        <div class="row fw-bold">
          <div class="col-5">Номенклатура</div>
          <div class="col-2">Факт</div>
          <div class="col">Готово</div>
          <div v-if="isOrderDraftCheckProduct()" class="col">Проверено</div>
          <div v-if="isOrderDraftCheckProduct()" class="col">Перепроверить</div>
        </div>

        <div>
          <div
            v-for="(goodsRow, productIndex) in docGoodsFilter"
            :key="goodsRow._id"
            :id="`inventory-row-${productIndex}`"
            class="row cols-4 border mt-1"
            @click="selectRow(goodsRow._id, productIndex)"
            :class="{
              'selected-row':
                goodsRow._id == currentRowId && !fillProductByBarcode,
              'adding-barcode-product':
                goodsRow._id == currentRowId && addingBarcode,
              'fill-product-by-barcode':
                goodsRow._id == currentRowId && fillProductByBarcode,
              'recheck-row': goodsRow.recheck,
              'checked-row': goodsRow.checked,
            }"
          >
            <div class="col-5 product-label px-0">
              <div v-if="!goodsRow.collected">
                <Multiselect
                  v-model="goodsRow.guid"
                  :options="products"
                  :loading="false"
                  :internal-search="false"
                  valueProp="guid"
                  trackBy="name"
                  label="name"
                  :filterResults="true"
                  :minChars="2"
                  :resolveOnLoad="true"
                  :searchable="true"
                  noOptionsText="Не найден"
                  :limit="40"
                  :disabled="!isOrderDraftWriteProduct()"
                  :class="{ 'product-input-wide': isMobile }"
                  @change="productSelected(goodsRow)"
                >
                  <template v-slot:singlelabel="option">
                    <div class="multiselect-single-label">
                      <span
                        class="multiselect-single-label-text1 single-label"
                        >{{ option.value.name }}</span
                      >
                    </div>
                  </template>
                </Multiselect>
                <span v-if="showClosedDate" class="fst-italic">
                  {{ dateFormat(goodsRow.selectDate) }} -
                  {{ goodsRow.userName }}
                </span>

                <button
                  v-if="isOrderDraftWriteProduct()"
                  class="btn btn-secondary btn-sm mx-1"
                  @click="
                    fillProductByBarcode = fillProductByBarcode ? false : true
                  "
                >
                  ТСД
                </button>
                <button
                  v-if="isOrderDraftWriteProduct()"
                  class="btn btn-warning btn-sm mx-1"
                  @click="startScan()"
                >
                  МОБИЛ
                </button>
              </div>

              <div v-if="goodsRow.collected">
                <Multiselect
                  v-if="goodsRow.newRow"
                  v-model="goodsRow.guid"
                  :options="products"
                  :loading="false"
                  :internal-search="false"
                  valueProp="guid"
                  trackBy="name"
                  label="name"
                  :filterResults="true"
                  :minChars="2"
                  :resolveOnLoad="true"
                  :searchable="true"
                  noOptionsText="Не найден"
                  :limit="10"
                  disabled
                  class="product-label"
                  :class="{ 'product-input-wide-div': isMobile }"
                >
                  <template v-slot:singlelabel="option">
                    <div class="multiselect-single-label">
                      <span
                        class="multiselect-single-label-text1 single-label"
                        >{{ option.value.name }}</span
                      >
                    </div>
                  </template>
                </Multiselect>
                <span v-if="!goodsRow.newRow">{{ goodsRow.productName }}</span>
                <p v-if="showClosedDate" class="fst-italic">
                  {{ dateFormat(goodsRow.selectDate) }} -
                  {{ goodsRow.userName }}
                </p>
              </div>

              <Multiselect
                class="mt-1"
                :class="{ 'fw-bold': goodsRow.collected }"
                v-model="goodsRow.position"
                :options="positions"
                :loading="false"
                :internal-search="false"
                valueProp="_id"
                trackBy="name"
                label="name"
                :filterResults="true"
                :minChars="2"
                :resolveOnLoad="true"
                :searchable="true"
                noOptionsText="Не найден"
                :limit="10"
                :disabled="goodsRow.collected || !isOrderDraftWriteProduct()"
                @input="goodsRow.edited = true"
              >
              </Multiselect>
            </div>

            <div class="col-2 px-0" style1="min-width: 50px; max-width: 70px;">
              <input
                v-model="goodsRow.qtyFact"
                type="number"
                class="form-control px-0"
                placeholder=""
                @input="goodsRow.edited = true"
                :disabled="goodsRow.collected || !isOrderDraftWriteProduct()"
              />

              <input
                v-model="goodsRow.expDate"
                type="text"
                class="form-control px-0 exp-date"
                placeholder="Сроки"
                @input="goodsRow.edited = true"
                :disabled="goodsRow.collected || !isOrderDraftWriteProduct()"
                :id="`exp-date-${goodsRow._id}`"
              />
            </div>
            <div class="col" style1="max-width: 180px;">
              <div class="row">
                <div class="col">
                  <div
                    class="
                form-check form-switch
                mb-3
                "
                  >
                    <input
                      style="width: 3rem; height: 1.5rem"
                      class="form-check-input"
                      type="checkbox"
                      v-model="goodsRow.collected"
                      @change="fillCollected(productIndex)"
                      :disabled="
                        goodsRow.collected ||
                          !isOrderDraftWriteProduct() ||
                          !goodsRow.position
                      "
                    />
                  </div>
                  <span v-if="showClosedDate" class="fst-italic">
                    {{ dateFormat(goodsRow.closedDate) }}
                  </span>
                </div>
                <div class="col">
                  <button
                    class="btn btn-light mt-1"
                    @click="addingBarcode = addingBarcode ? false : true"
                  >
                    ШК({{ goodsRow.barcode.length }})+
                  </button>
                  <button
                    v-if="
                      !goodsRow.spinnerSaveRow &&
                        goodsRow.edited &&
                        (isOrderDraftWriteProduct() ||
                          isOrderDraftCheckProduct())
                    "
                    class="btn btn-primary mx-1 mt-1"
                    @click="saveSkladOrderDraftRow(goodsRow)"
                  >
                    Сохр
                  </button>
                  <div
                    v-if="goodsRow.spinnerSaveRow"
                    class="d-flex justify-content-center mt-3"
                  >
                    <div
                      class="spinner-border text-primary d-flex justify-content-center"
                      role="status"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isOrderDraftCheckProduct()" class="col">
              <div class="col">
                <div
                  class="
                form-check form-switch
                mb-1
                "
                >
                  <input
                    style="width: 3rem; height: 1.5rem"
                    class="form-check-input"
                    :class="{ 'check-btn-success': goodsRow.checked }"
                    type="checkbox"
                    v-model="goodsRow.checked"
                    @click="checkRow(goodsRow)"
                  />
                </div>
              </div>
            </div>

            <div v-if="isOrderDraftCheckProduct()" class="col">
              <div class="col">
                <div
                  class="
                form-check form-switch
                mb-1
                "
                >
                  <input
                    style="width: 3rem; height: 1.5rem"
                    class="form-check-input"
                    :class="{ 'check-btn-danger': goodsRow.recheck }"
                    type="checkbox"
                    v-model="goodsRow.recheck"
                    @click="recheckRow(goodsRow)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="spinnerLoading" class="d-flex justify-content-center mt-3">
      <div
        class="spinner-border text-primary d-flex justify-content-center"
        role="status"
      ></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed, nextTick } from "vue";
//import { onMounted, onUnmounted } from "vue";
import Multiselect from "@vueform/multiselect";
//import router from "../router";
import { v4 as uuidv4 } from "uuid";
import useCommonMixin from "@/components/commonMixin.js";
import Inputmask from "inputmask";

export default {
  components: { Multiselect },
  props: {
    deliveryDate: { type: String, default: null },
    clipboardText: { type: String, default: null },
    clipboardActive: { type: Boolean, default: false },
    showClosedDate: { type: Boolean, default: false },
    isList: { type: Boolean, default: false },
    searchTextList: { type: String, default: null },
    store: { type: String, default: "" },
  },

  setup(props, { emit }) {
    const spinnerSave = ref(false);
    const spinnerLoading = ref(false);
    const dateFormat = (date) => {
      let dateFormated = "";
      if (date) {
        dateFormated = new Date(date);
        dateFormated = dateFormated.toLocaleString();
      }
      return dateFormated;
    };

    const products = ref([]);
    const stores = ref([]);
    const positions = ref([]);
    const addingBarcode = ref(false);
    const searchText = ref("");
    const searchTextTemp = ref("");
    const fillProductByBarcode = ref(false);

    const filterCursor = ref(10);
    const filterStep = ref(100);
    const filterMax = ref(200);
    const errorSaving = ref("");
    const searchTextFocused = ref(false);

    const seachText = ref("");

    const autoFillPositions = ref([]);

    const {
      isMobile,
      getSettings,
      scan,
      scannedText,
      getAutoFillPositions,
      postSkladOrderDraftRow,
    } = useCommonMixin();

    const isOrderDraftWriteProduct = () => {
      return JSON.parse(localStorage.getItem("isOrderDraftWriteProduct"));
    };

    const isOrderDraftCheckProduct = () => {
      return JSON.parse(localStorage.getItem("isOrderDraftCheckProduct"));
    };

    const userId = () => {
      return localStorage.getItem("userId");
    };

    watch(
      () => props.clipboardText,
      () => {
        console.log("watch clipboardText", props.clipboardText);
        if (props.clipboardActive) {
          handleClipboard(props.clipboardText);
        }
        emit("update:clipboard-text", "");
      }
    );

    watch(
      () => props.searchTextList,
      () => {
        console.log("watch searchTextList", props.searchTextList);
        if (props.isList) {
          searchText.value = props.searchTextList;
        }
      }
    );

    const applyMask = async (elementId=null) => {
      await nextTick();
      let expDateElements = []
      if (elementId) {
        let element = document.getElementById(elementId)
        expDateElements.push(element)
      } else {
        expDateElements = document.getElementsByClassName("exp-date");
      }
      if (expDateElements != undefined) {
        for (let index = 0; index < expDateElements.length; index++) {
          const element = expDateElements[index];
          var im = new Inputmask("99.99.99-99.99.99");
          im.mask(element);
        }
      }
    };

    const startScan = async () => {
      fillProductByBarcode.value = fillProductByBarcode.value ? false : true;
      scan(true);
      scrollTo("scanner");
    };

    watch(scannedText, () => {
      console.log("scannedText", scannedText.value);
      handleClipboard(scannedText.value);
      let rowId = `inventory-row-${currentRow.value}`;
      scrollTo(rowId);
    });

    const getDoc = async () => {
      console.log("props.deliveryDate", props.deliveryDate);
      let res = await fetch(
        process.env.VUE_APP_API_URL +
          "/getSkladOrderDraft/byDate/" +
          props.deliveryDate + 
          "/store/" + 
          props.store,
        {
          method: "GET",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
          },
        }
      );
      console.log(res.status);

      if (res.status == 200) {
        doc.value = await res.json();
      }
    };

    // const getDoc = async () => {
    //   console.log("props.id", props.id);
    //   let res = await fetch(
    //     process.env.VUE_APP_API_URL +
    //       "/getSkladOrderDraft/byId/" +
    //       props.id,
    //     {
    //       method: "GET",
    //       headers: {
    //         Authorization:
    //           "Basic " +
    //           btoa("username" + ":" + localStorage.getItem("password")),
    //       },
    //     }
    //   );
    //   console.log(res.status);

    //   if (res.status == 200) {
    //     doc.value = await res.json();
    //   }
    // };

    const saveDoc = async () => {
      spinnerSave.value = true;
      errorSaving.value = "";

      let res = await fetch(
        process.env.VUE_APP_API_URL + "/saveSkladOrderDraft/",
        {
          method: "POST",
          headers: {
            Authorization:
              "Basic " +
              btoa("username" + ":" + localStorage.getItem("password")),
            "Content-Type": "application/json;charset=utf-8",
          },
          body: JSON.stringify(doc.value),
        }
      );
      console.log(res.status);
      if (res.status == 200) {
        spinnerSave.value = false;
        doc.value = await res.json();
      } else {
        errorSaving.value = await res.json();
        spinnerSave.value = false;
      }
    };

    const getProducts = async () => {
      let res = await fetch(process.env.VUE_APP_API_URL + "/getProducts/", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      console.log(res.status);

      if (res.status == 200) {
        products.value = await res.json();
      }
    };

    const getPositions = async () => {
      let res = await fetch(process.env.VUE_APP_API_URL + "/getPositions/", {
        method: "GET",
        headers: {
          Authorization:
            "Basic " +
            btoa("username" + ":" + localStorage.getItem("password")),
        },
      });
      console.log(res);
      if (res.status == 200) {
        positions.value = await res.json();
      }
    };

    const getProductName = (productId) => {
      let name = "";
      let productIndex = products.value.findIndex(
        (product) => product._id == productId
      );
      name = productIndex >= 0 ? products.value[productIndex].name : "";
      return name;
    };

    const doc = ref([]);

    const removeProductPosition = (row, positionIndex) => {
      row.positions.splice(positionIndex, 1);
      // let rowIndex = doc.value.goods.findIndex((item) => item._id == rowId)
      // doc.value.goods[productIndex].positions.splice(positionIndex, 1);
    };

    const fillCollected = (index) => {
      doc.value.goods[index].edited = true;
      if (!doc.value.goods[index].qtyFact && doc.value.goods[index].collected) {
        doc.value.goods[index].qtyFact = doc.value.goods[index].qtyOrder;
      }

      if (doc.value.goods[index].collected) {
        let CurrentTime = new Date();
        CurrentTime.setHours(CurrentTime.getHours() + 3);
        doc.value.goods[index].closedDate = CurrentTime.toISOString().substring(
          0,
          19
        );
      }
    };

    const productSelected = async (goodsRow) => {
      await nextTick();
      let CurrentTime = new Date();
      CurrentTime.setHours(CurrentTime.getHours() + 3);
      goodsRow.selectDate = CurrentTime.toISOString().substring(0, 19);

      let autoFillPositionIndex = autoFillPositions.value.findIndex(
        (positionItem) => positionItem.productGuid == goodsRow.guid
      );
      console.log("autoFillPositionIndex", autoFillPositionIndex);
      let newPosition = "";
      if (autoFillPositionIndex >= 0) {
        newPosition = autoFillPositions.value[autoFillPositionIndex].position;
      }
      goodsRow.position = newPosition;
      goodsRow.edited = true;
    };

    const currentRow = ref(null);
    const currentRowId = ref(null);
    const selectRow = (rowId, productIndex) => {
      let rowIndex = doc.value.goods.findIndex((item) => item._id == rowId);
      currentRow.value = rowIndex;
      currentRowId.value = rowId;
      filterCursor.value = productIndex;
    };

    const isPosition = (text) => {
      let positionIndex = positions.value.findIndex(
        (item) => item.guid == text
      );
      console.log("positionIndex", text, positionIndex);

      if (positionIndex >= 0) {
        doc.value.goods[currentRow.value].position =
          positions.value[positionIndex]._id;
        fillProductByBarcode.value = false;
        doc.value.goods[currentRow.value].edited = true;
      }
      return positionIndex >= 0;
    };

    const addBarcode = (code, productIndex) => {
      if (productIndex >= 0 && code.length > 0) {
        let currentProduct = doc.value.goods[productIndex];
        let codeIndex = currentProduct.barcode.findIndex(
          (item) => item == code
        );
        if (codeIndex < 0) {
          currentProduct.barcode.push(code);
          doc.value.goods[productIndex].edited = true;
        }
      }
    };

    const scrollTo = (elementId) => {
      let rowEl = document.getElementById(elementId);
      rowEl.scrollIntoView({ block: "center", behavior: "smooth" });
    };

    const handleClipboard = (text) => {
      console.log("handleClipboard new", text);
      let textIsPosition = isPosition(text);
      if (!textIsPosition) {
        if (fillProductByBarcode.value) {
          let productIndex = products.value.findIndex((product) => {
            let barcodeIndex = product.barcodes.findIndex(
              (barcodeItem) => text.indexOf(barcodeItem) >= 0
            );
            return barcodeIndex >= 0;
          });
          if (productIndex >= 0) {
            doc.value.goods[currentRow.value].guid =
              products.value[productIndex].guid;
            productSelected(doc.value.goods[currentRow.value]);
          }

          fillProductByBarcode.value = false;
        } else if (addingBarcode.value) {
          // console.log("addingBarcode");
          addBarcode(text, currentRow.value);
          addingBarcode.value = false;
        } else {
          let rowIndex = doc.value.goods.findIndex((row) => {
            let barcodeIndex = row.barcode.findIndex(
              (barcodeItem) => text.indexOf(barcodeItem) >= 0
            );
            return barcodeIndex >= 0;
          });

          if (rowIndex >= 0) {
            selectRow(rowIndex);
            //scrollToRow(rowIndex);
          }
        }
      }
    };
    const spinnerSearch = ref(true);
    const docGoodsFilter = computed(() => {
      let result = doc.value.goods;
      let searchT = searchText.value.toLowerCase();

      if (searchText.value.length >= 3) {
        result = result.filter(
          (row) =>
            JSON.stringify(row)
              .toLowerCase()
              .indexOf(searchT) >= 0
        );
      }

      if (result && result.length > filterMax.value) {
        let filterStart =
          filterCursor.value - filterStep.value > 0
            ? filterCursor.value - filterStep.value
            : 0;
        let filterEnd = filterCursor.value + filterStep.value;

        result = result.slice(filterStart, filterEnd);
      }
      return result;
    });

    const addRow = () => {
      let newRow = {
        product: null,
        productName: "",
        qtyOrder: 0,
        qtyFact: "",
        positions: [],
        position: null,
        barcode: [],
        newRow: true,
        collected: false,
        _id: uuidv4(),
        guid: null,
        user: null,
        userName: "",
        edited: true,
      };
      setRowAuthor(newRow);
      doc.value.goods.unshift(newRow);

      applyMask(`exp-date-${newRow._id}`)
    };

    const setRowAuthor = (row) => {
      row.user = userId();
    };

    const clearSearchText = () => {
      searchText.value = "";
      searchTextTemp.value = "";
    };

    const checkRow = (row) => {
      row.recheck = false;
      let rowIndex = doc.value.goods.findIndex(
        (rowItem) => rowItem._id == row._id
      );
      if (rowIndex >= 0) {
        row.edited = true;
        doc.value.goods.push(row);
        doc.value.goods.splice(rowIndex, 1);
      }
    };

    const recheckRow = (row) => {
      row.collected = false;
      row.checked = false;
      let rowIndex = doc.value.goods.findIndex(
        (rowItem) => rowItem._id == row._id
      );
      if (rowIndex >= 0) {
        row.edited = true;
        let rowTmp = JSON.parse(JSON.stringify(row));
        doc.value.goods.splice(rowIndex, 1);
        doc.value.goods.unshift(rowTmp);
      }
    };

    const saveSkladOrderDraftRow = async (goodsRow) => {
      goodsRow.spinnerSaveRow = true;
      let dataResp = {
        docId: doc.value._id,
        rowItem: goodsRow,
      };
      let saveRowResp = await postSkladOrderDraftRow(dataResp);
      if (saveRowResp.status) {
        console.log(saveRowResp.data);
        goodsRow.edited = false
        goodsRow._id = saveRowResp.data._id
      }
      goodsRow.spinnerSaveRow = false;
    };

    onMounted(async () => {
      spinnerLoading.value = true;
      await getProducts();
      await getPositions();

      if (props.deliveryDate) {
        await getDoc();
      }
      spinnerLoading.value = false;

      await getSettings();

      let autoFillPositionsResp = await getAutoFillPositions();
      if (autoFillPositionsResp.status) {
        autoFillPositions.value = autoFillPositionsResp.data.data;
      }

      applyMask();
    });

    return {
      getDoc,
      doc,
      dateFormat,
      saveDoc,
      spinnerSave,
      stores,
      products,
      getProductName,
      spinnerLoading,
      positions,
      removeProductPosition,
      fillCollected,
      selectRow,
      currentRow,
      addingBarcode,
      searchText,
      docGoodsFilter,
      addRow,
      fillProductByBarcode,
      filterCursor,
      searchTextTemp,
      clearSearchText,
      currentRowId,
      searchTextFocused,
      spinnerSearch,
      errorSaving,
      seachText,
      isOrderDraftWriteProduct,
      isOrderDraftCheckProduct,
      isMobile,
      startScan,
      productSelected,
      autoFillPositions,
      checkRow,
      recheckRow,
      saveSkladOrderDraftRow,
    };
  },
};
</script>

<style>
:root {
  --ms-px: 0.4rem;
}

.lh {
  line-height: 1;
}

.single-label {
  line-height: 100%;
}

.selected-row {
  background-color: #949997 !important;
}
.adding-barcode-product {
  background-color: #e1f100a2;
}
.fill-product-by-barcode {
  background-color: #1000f149;
}

.product-input-wide input {
  height: 90px !important;
  position: inherit !important;
}
.product-input-wide-div div {
  height: 90px !important;
  position: inherit !important;
  padding-left: 20px;
}

.check-btn-success {
  accent-color: #07fa9d;
  background-color: #07fa9d !important;
}

.check-btn-danger {
  accent-color: #e69292;
  background-color: #e69292 !important;
}

.checked-row {
  background-color: #07fa9d;
}

.recheck-row {
  background-color: #e69292;
}
</style>
